import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Box } from '@material-ui/core';
import 'fontsource-roboto';
import 'bootstrap/dist/css/bootstrap.min.css';
import LogRocket from 'logrocket';
import _ from 'lodash';

import Routes from 'shared-lib/src/utils/Routes';

import EmployerThemes from 'shared-lib/src/utils/EmployerThemes';
import EnterpriseStepLinkBank from 'shared-lib/src/ui/steps_enterprise/EnterpriseStepLinkBank';
import EnterpriseStepLinkStripe from 'shared-lib/src/ui/steps_enterprise/EnterpriseStepLinkStripe';
import EnterpriseSuccess from 'shared-lib/src/ui/steps_enterprise/EnterpriseSuccess';
import EnterpriseErrorPage from 'shared-lib/src/ui/steps_enterprise/EnterpriseErrorPage';

import ConfigContext from 'shared-lib/src/ui/ConfigContext';

import FooterBar from './ui/FooterBar';
import NavBar from './ui/NavBar';
import current from "./config";
import { createHashHistory } from 'history';


const PARAMETERS_PREPEND = '?';
const PARAMETERS_LINK = '&';
const PARAMETER_LINK = '=';
// the only ones we support right now
const PARAMETER_KEY_ERROR = 'error';
const PARAMETER_KEY_EMPLOYER = 'for';



LogRocket.init('wrqwym/onboarding', {
  network: {
    requestSanitizer: request => {
      request.headers['Authorization'] = null;
      if (request.url.toLowerCase().indexOf('link') !== -1) {
        request.body = null;
      }
      else if(request.url.toLowerCase().indexOf('auth') !== -1) {
        request = null;
      }
        
      return request;
    },
  },
});

const stripeNotFinishedText = "You left Stripe early but can go back and finish when you're ready."

export default function Onboarding() {
  // TODO: we have to decide based on something to show the 2-step enterprise
  // TODO: there might be a more built-in way to do this
  const history = createHashHistory();
  const {error} = getParamsFromURL();
  const employer = window.location.hash.match(/9f6a/) ? 'jamf123' : null;
  console.log('error', error);
  console.log('employer', employer);
  let backgroundColor = EmployerThemes.getBackgroundColorForEmployer(employer);

  const bodyRouter = (
    <Router history={history}>
        <Switch>
          <Route path={Routes.ENTERPRISE_BANK}>
            <EnterpriseStepLinkBank employer={employer} history={history} />
          </Route>

          <Route path={Routes.ENTERPRISE_SUCCESS}>
            <EnterpriseSuccess employer={employer} history={history} />
          </Route>

          <Route path={Routes.ENTERPRISE_STRIPE_NOT_FINISHED}>
            <EnterpriseStepLinkStripe employer={employer} history={history} title={stripeNotFinishedText} />
          </Route>

          <Route path={Routes.ENTERPRISE_ERROR}>
            <EnterpriseErrorPage employer={employer} history={history} />
          </Route>

          <Route path={Routes.ENTERPRISE_HOME}>
            <EnterpriseStepLinkStripe employer={employer} history={history} />
          </Route>
          
        </Switch>
      </Router>
  );

  return (
    <ConfigContext.Provider value={current}>
      <Box height="100%" style={{display: 'flex', flexDirection: 'column', backgroundColor}}>
        <NavBar employer={employer} />
        <div style={{flex: 1, flexDirection: 'column'}}>
          {bodyRouter}
        </div>
        <FooterBar employer={employer} />
      </Box>
    </ConfigContext.Provider>
  );
}

const getParamsFromURL = () => {
  const urlParts = window.location.hash.split(PARAMETERS_PREPEND);
  console.log('urlparts', urlParts);
  let error = null;
  let employer = null;

  if (urlParts.length > 1) {
      const params = urlParts[1];
      const parameters = params.split(PARAMETERS_LINK);
      _.forEach(parameters, parameterData => {
          const parameterParts = parameterData.split(PARAMETER_LINK);
          const key = parameterParts[0];
          const value = parameterParts[1];
          
          // TODO: continue to do this for any and all other parameters
          switch (key) {
            case PARAMETER_KEY_ERROR:
              error = value;
              break;
            case PARAMETER_KEY_EMPLOYER:
              employer = value;
              break;
            default:
              break;
          }
      });
  }

  return {error, employer};
};