import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {Box, Container, Grid} from '@material-ui/core';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import _ from "lodash";

import Colors from '../utils/Colors';
import EmployerThemes from 'shared-lib/src/utils/EmployerThemes';
import RouteFlow from '../utils/RouteFlow';
import Routes from '../utils/Routes';
import SessionStorage from '../utils/SessionStorage';
import StyledButton from './components/StyledButton';
import StyledText from './components/StyledText';

const ERROR_KEY = 'error';
const PARAMETER_LINK = '=';

type Props = {
    children: React.Node,
    errorMessage?: string | null, 
    illustration: string,
    isRedirecting: boolean,
    isButtonDisabled: boolean,
    showSpinner: boolean,
    buttonLabel?: string | null, 
    onClickButton: () => void,
    route: string,
    title: string,
};

function EnterpriseStepPage(props: Props) {
    const {buttonLabel, children, employer, errorMessage, illustration, isButtonDisabled, isRedirecting, onClickButton, route, title, showSpinner} = props;
    
    const userLabel = buttonLabel || 'Continue';
    const label = isRedirecting ? 'Redirecting...' : userLabel;

    let backgroundColor = EmployerThemes.getBackgroundColorForEmployer(employer);

    return (
        <div style={{
            backgroundColor, 
            display: 'flex', 
            alignItems: 'center',
            height: '100%',
        }}>
            <Container maxWidth="md">
                <div style={{backgroundColor: 'white', borderRadius: 8, padding: 36, flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{flex: 1}}>
                        <div style={{marginBottom: 24, marginTop: 32}}>
                            <StyledText use="header">{title}</StyledText>
                        </div>
                        <ErrorSection errorMessage={errorMessage} />
                        {children}
                        <Footer 
                            showSpinner={showSpinner}
                            isButtonDisabled={isRedirecting || isButtonDisabled}
                            label={label}
                            onClickButton={onClickButton}
                        />
                    </div>
                    {
                        !!illustration &&
                        (
                            <div style={{flex: 1}}>
                                <img
                                    alt="illustration"
                                    src={illustration}
                                    style={{float: 'right'}}
                                />
                            </div>
                        )
                    }
                </div>
            </Container>
        </div>
    );
}

type FooterProps = {
    isButtonDisabled: boolean, 
    showSpinner: boolean,
    label?: string | null, 
    onClickButton: () => void,
};
function Footer(props: FooterProps) {
    const {isButtonDisabled, label, onClickButton, showSpinner} = props;
    if (onClickButton == null) {
        return null;
    }

    // TODO: fill height to onClickButtonmin 100% of left nav, push button down below
    return (
        <div style={{marginTop: 24}}>
            {/* Float right if we want justify="flex-end"> */}
            <Grid container direction="row"> 
                <StyledButton 
                    showSpinner={showSpinner}
                    onClick={onClickButton} 
                    isDisabled={isButtonDisabled} 
                    label={label || 'Continue'} 
                />
            </Grid>
        </div>
    );
}

function ErrorSection(props: ErrorProps) {
    let {errorMessage} = props;

    // If still no error, let's bounce
    if (!errorMessage) {
        return null;
    }

    // TODO: design this to be good
    return (
        <div>
            <Alert variant="danger">
                {errorMessage}
            </Alert>
        </div>
    );
}

export default EnterpriseStepPage;