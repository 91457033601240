import React from "react";
import {Grid} from '@material-ui/core';

import EmployerThemes from 'shared-lib/src/utils/EmployerThemes';

import logo from '../Benepass_Logo.png';

function NavBar(props) {
    const {employer} = props;

    let backgroundColor = EmployerThemes.getBackgroundColorForEmployer(employer);

    let employerLogo = EmployerThemes.getLogoForEmployer(employer);
    if (employerLogo === null) {
        employerLogo = logo;
    }
    
    return (
        <div style={{backgroundColor, flexDirection: 'row', paddingTop: 32, width: '100%'}}>
            <Grid container direction="row" justify="center">  
                <img
                    alt="Employer logo"
                    src={employerLogo}
                    width="200"
                />
            </Grid>
        </div>
    );
}

export default NavBar;
