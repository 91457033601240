import React, { useState, useContext } from "react";

import Routes from '../../utils/Routes';
import EnterpriseStepPage from '../EnterpriseStepPage';
import StyledText from '../components/StyledText';
import illustration from 'shared-lib/assets/illustrations/onboarding-1.svg';
import {useHistory} from 'react-router-dom';


// TODO: share this in utils or do this better, investigate hash utils
const ERROR_PARAMETER_TEXT = '?error=';

function EnterpriseStepLinkStripe(props) {
    const {employer, title, history} = props;
    const path = window.location.hash.split('/')
    const nonce = path[2];
    
    const [isDisabled, setIsDisabled] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const [error, setError] = useState(null);

    const onChangeName = (event) => setCompanyName(event.target.value);

    const route = Routes.STEP_LINK_STRIPE;

    const goToStripe = async () => {
        setIsDisabled(true);
        setShowSpinner(true);
        try {
            const result = await fetch(`https://us-central1-benepass.cloudfunctions.net/initOnboarding?nonce=${nonce}`);
            const {stripeOnboardingLink} = await result.json();
            window.location.href = stripeOnboardingLink;            
        }
        catch(err) {
            setShowSpinner(false);
            setIsDisabled(false);
            history.push(Routes.ENTERPRISE_ERROR);
        }
        
    }

    return (
        <EnterpriseStepPage
            employer={employer}
            errorMessage={error}
            illustration={illustration}
            showSpinner={showSpinner}
            isButtonDisabled={isDisabled}
            onClickButton={goToStripe}
            route={route}
            title={title || "Let’s finish creating your account."}>
            <StyledText>
                <div style={{marginBottom: 32, marginTop: 20}}>
                    Compliance regulations require that we collect this information to 
                    confirm your business identity and manage your corporate 
                    account. We take privacy seriously, and this information is 
                    stored securely using bank-level encryption.
                </div>
            </StyledText>
        </EnterpriseStepPage>
    );
}

export default EnterpriseStepLinkStripe;
