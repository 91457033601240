const staging = {
    auth: {
        domain: "benefitsapi-dev.auth0.com",
        clientId: "DCEYYskQYzaNLZYcvkwq5lTF29LHWZaU",
        audience: "testing",
    },
    plaid: {environment: "sandbox"},
    api: {origin: "https://staging.benefitsapi.com/"}
};

const production = {
    auth: {
        domain: "auth.benefitsapi.com",
        clientId: "amLYUofLrIoP24yfyTAhTQsDgO8v4a13",
        audience: "api.benefitsapi.com",
    },
    plaid: {environment: "production"},
    api: {origin: "https://api.benefitsapi.com/"}
};

export default staging;