import React, { useState, useContext } from "react";
import { Grid,TextField,Link } from '@material-ui/core';

import EnterpriseStepPage from '../EnterpriseStepPage';
import Routes from '../../utils/Routes';
import StyledText from '../components/StyledText';
import illustration from 'shared-lib/assets/illustrations/onboarding-2.svg';

// TODO: share this in utils or do this better, investigate hash utils
const ERROR_PARAMETER_TEXT = '?error=';

const ModeTypes = {
    AUTOMATIC: 'automatic',
    MANUAL: 'manual',
};

function EnterpriseStepLinkBank(props) {
    const {employer, history} = props;
    const path = window.location.hash.split('/');
    const nonce = path[2];

    const [routing, setRouting] = useState("");
    const [account, setAccount] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);    
    const [error, setError] = useState(null);

    const linkMicrodeposits = async () => {
        try { 
            setShowSpinner(true);
            setIsDisabled(true);
            const response = await fetch(
                `https://us-central1-benepass.cloudfunctions.net/linkBankAccount`,
                {
                    method: 'post',
                    body: JSON.stringify({nonce, accountNumber: account, routingNumber: routing}),
                    headers: {'Content-Type': 'application/json'}
                }
            );
            handleResultsOrError(response, history);
        }
        catch(err) {
            setShowSpinner(false);
            setIsDisabled(false);
            history.push("/9f6a/error");
        }
    };

    return (
        <EnterpriseStepPage 
            buttonLabel="Link Account"
            employer={employer}
            errorMessage={error}
            illustration={illustration}
            onClickButton={linkMicrodeposits}
            showSpinner={showSpinner}
            isButtonDisabled={isDisabled || routing.length === 0 || account.length === 0}
            route={Routes.ENTERPRISE_BANK} 
            title="Link your bank account.">
            <div style={{marginBottom: 20}}>
                <StyledText>
                    We’ll automatically ACH funds as needed to fund the cards and 
                    manage a completely auditable ledger so you can track 
                    benefits usage.
                </StyledText>
            </div>
            <div style={{marginTop: 30, marginBottom: 30}}>
                <Grid container spacing={5}>
                    <Grid item>
                        <TextField 
                        required 
                            style={{fontFamily: 'Basier Circle'}}
                            InputLabelProps={{
                                shrink: true,
                              }}
                            label="Routing Number" 
                            type="number"
                            placeholder="000000000" 
                            value={routing} 
                            onChange={e => setRouting(e.target.value.trim())}
                        />
                    </Grid>
                    <Grid item>
                        <TextField 
                        required 
                            InputLabelProps={{
                                shrink: true,
                              }}
                            style={{fontFamily: 'Basier Circle'}}
                            label="Account Number" 
                            placeholder="12345678" 
                            type="number"
                            value={account} 
                            onChange={e => setAccount(e.target.value.trim())}
                        />
                    </Grid>
                </Grid>
            </div>
        </EnterpriseStepPage>
    );
}

function handleResultsOrError(response, history): void {
    if (!response.ok) {
        // TODO: replace this with actual useful error
        // return 'Hmm, something went wrong. Please try again or contact support';
        history.push(Routes.ENTERPRISE_ERROR);
    } else {
        history.push(Routes.ENTERPRISE_SUCCESS);
    }
}

export default EnterpriseStepLinkBank;
