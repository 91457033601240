import React, { useState, useContext } from "react";

import Routes from '../../utils/Routes';
import EnterpriseStepPage from '../EnterpriseStepPage';
import StyledText from '../components/StyledText';
import illustration from 'shared-lib/assets/illustrations/onboarding-1.svg';
import {useHistory} from 'react-router-dom';


// TODO: share this in utils or do this better, investigate hash utils
const ERROR_PARAMETER_TEXT = '?error=';

function EnterpriseErrorPage(props) {
    const {employer} = props;
 
    const [error, setError] = useState(null);



    return (
        <EnterpriseStepPage
            employer={employer}
            errorMessage={error}
            route={Routes.ENTERPRISE_ERROR}
            title={"Hmm, that didn't work."}>
            <StyledText>
                <div style={{marginBottom: 32, marginTop: 20}}>
                    There was a problem setting up your account, but you can try again or talk to us.
                </div>
            </StyledText>
        </EnterpriseStepPage>
    );
}

export default EnterpriseErrorPage;
