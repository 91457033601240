import React from "react";
import {Button, CircularProgress} from '@material-ui/core';
// import Button from 'react-bootstrap/Button';

import Colors from '../../utils/Colors';

// const Modes = {
//     BODY: 'body',
//     HEADER: 'header',
//     SUBTITLE: 'subtitle',
// }

type Props = {
    isDisabled: boolean,
    showSpinner: boolean, 
    label: string,
    onClick: () => void,
    use: 'primary' | 'secondary',
};

const StyledButton = (props: Props) => {
    const {isDisabled, label, onClick, use, showSpinner} = props;

    // TODO: clean this up and  modesmake proper color
 
    const style = {
        backgroundColor: isDisabled ? Colors.BUTTON_NEXT_DISABLED : Colors.BUTTON_NEXT, 
        color: Colors.WHITE, 
        borderRadius: 10, 
        padding: '10px 45px', 
        fontFamily: 'Basier Circle', 
        textTransform: 'none',
    };
    return (
        // TODO: replace with our font, colors, etc.
        <Button 
            variant="contained"
            onClick={onClick} 
            disabled={isDisabled}
            style={style}>
            {!!showSpinner && <CircularProgress size={20} style={{marginRight: 10, color: 'white'}} /> } {label}
        </Button>
    );
};

export default StyledButton;