export default {
    STEP_LINK_STRIPE: '/', // 1
    STEP_LINK_BANK: '/link', // 2 /link_bank in the future?
    STEP_SELECT_BENEFITS: '/select', // 3
    STEP_TOA: '/toa', // 4
    STEP_LINK_PAYROLL: '/payroll', // 5
    STEP_SUCCESS: '/success', // 6

    // Enterprise
    ENTERPRISE_HOME: '/9f6a/:nonce/', // Entrp. 1
    ENTERPRISE_STRIPE_NOT_FINISHED: '/9f6a/:nonce/saved/', // Entrp. 1.5
    ENTERPRISE_BANK: '/9f6a/:nonce/bank/', // Entrp. 2
    ENTERPRISE_SUCCESS: '/9f6a/success/', // Entrp. 3
    ENTERPRISE_ERROR: '/9f6a/error/' // Entrp. 4
};
