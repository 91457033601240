import React from "react";

import Colors from '../../utils/Colors';

const Modes = {
    BODY: 'body',
    HEADER: 'header',
    SECONDARY: 'secondary',
    SECTION_HEADER: 'section_header',
    SUBTITLE: 'subtitle',
    TITLE: 'title',
}

type Props = {
    children: React.Node,
    use: string, // oneof Modes
};

const StyledText = (props: Props) => {
    const {children, use} = props;

    let textStyles = {};
    const h5styles = {
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: 1.334,
        letterSpacing: '0em',
    };

    const style = {};
    switch (use) {
        case Modes.HEADER:
            // Material h4
            textStyles = {
                fontSize: '2.125rem',
                fontWeight: 400,
                lineHeight: 1.235,
                letterSpacing: '0.00735em',
            };
            break;
        case Modes.SUBTITLE:
            textStyles = h5styles;
            break;
        case Modes.SECTION_HEADER:
            textStyles = h5styles;
            textStyles.color = Colors.TEXT_SECTION_HEADER;
            break;
        case Modes.TITLE:
            // Material h6
            textStyles = {
                fontSize: '1.25rem',
                fontWeight: 500,
                lineHeight: 1.6,
                letterSpacing: '0.0075em',
            };
            break;
        case Modes.SECONDARY:
            // Material body2
            style.color = Colors.SECONDARY_TEXT;
            textStyles = {
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
            };
            break;
        case Modes.BODY:
            // Material body
            textStyles = {
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
            };
            break;
        default:
            textStyles = {
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 1.5,
                letterSpacing: 0,
                textAlign: 'left'
            };            
            break;
    }

    // Applies to all
    textStyles.fontFamily = "\"Basier Circle\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif";

    return (
        // TODO: replace with our font, colors, etc.
        // TODO: Typography uses <p> which is dumb, let's reomve it
        <span style={textStyles}>
            {children}
        </span>
    );
};

export default StyledText;