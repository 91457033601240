import React from "react";


import Routes from '../../utils/Routes';
import EnterpriseStepPage from '../EnterpriseStepPage';
import StyledText from '../components/StyledText';
import illustration from 'shared-lib/assets/illustrations/onboarding-3.svg';


function EnterpriseSuccess(props) {
    const {employer} = props;

    return (
        <EnterpriseStepPage 
            employer={employer}
            route={Routes.ENTERPRISE_SUCCESS} 
            illustration={illustration}
            illustrationPosition="right"
            title="You're all set!">
            <div style={{marginBottom: 20}}>
                <StyledText>
                    Now it's our turn. We'll send you a confirmation email once your
                    business and bank account details have been confirmed through compliance.
                    Thanks again for signing up with Benepass!
                    <br />
                    <br />
                    You can now close this tab.
                </StyledText>
            </div>
        </EnterpriseStepPage>
    );
}

export default EnterpriseSuccess;
