import JamfColor from '../../assets/employers/jamf_background.jpg';
import Colors from './Colors';

const Employers = {
    JAMF: 'jamf123',
};

const getNameForEmployer = (employer: string): string | null => {
    switch (employer) {
        case Employers.JAMF:
            return 'Jamf';
        default:
            return null;
    }
};

const getBackgroundColorForEmployer = (employer: string): string | null => {
    switch (employer) {
        case Employers.JAMF:
            return '#424B54';
            // return '#778EB1';
        default:
            return Colors.BACKGROUND_GRAY;
    }
};

const getLogoForEmployer = (employer: string): string | null => {
    switch (employer) {
        case Employers.JAMF:
            return JamfColor;
        default:
            return null;
    }
};

export default {
    getBackgroundColorForEmployer,
    getLogoForEmployer,
};