// Our Colors
const brightPink = '#F72585';
const dullPink = '#FFC4DF';
// const blueTepid = '#3ca0c0';
const blueBootstrap = '#007CFE';
const purpleDark = '#463090';
const white = '#FFFFFF';

// UX Colors
const lightBlue = '#007cfe'
const mediumGray = '#666';
const blueGray = '#F8F9FA';
const gray = '#777777';
const black = '#1C1C1C';

export default {
    BACKGROUND_GRAY: blueGray,
    BUTTON_PRIMARY: blueBootstrap,
    BUTTON_NEXT: brightPink,
    BUTTON_NEXT_DISABLED: dullPink,
    FOOTER_LOG_OUT_TEXT: black,
    FOOTER_TEXT: gray,
    SECONDARY_TEXT: mediumGray,
    STATUS_STEP_CURRENT: lightBlue,
    TEXT_SECTION_HEADER: purpleDark,
    WHITE: white,
};