import React from "react";
import {Grid} from '@material-ui/core';

import logo from 'shared-lib/assets/logos/Benepass_Logo_White.png';

import Colors from 'shared-lib/src/utils/Colors';
import EmployerThemes from 'shared-lib/src/utils/EmployerThemes';

// const Props = {
//     employer?: string | null,
// };
function FooterBar(props): React.Node {
    const {employer} = props;

    let backgroundColor = EmployerThemes.getBackgroundColorForEmployer(employer);
    let loggedInText = Colors.FOOTER_TEXT;
    let logOutText = Colors.FOOTER_LOG_OUT_TEXT;
    if (employer) {
        loggedInText = Colors.WHITE;
        logOutText = Colors.WHITE;
    }

    let employerPoweredBy = null;
    if (employer) {
        employerPoweredBy = (
            <Grid 
                container 
                direction="row" 
                justify="center" 
                style={{justifyContent: 'center', alignItems: 'center', marginTop: 12, marginBottom: 12}}>
                <div style={{color: loggedInText, fontSize: 12, marginRight: 8}}>
                    Powered by 
                </div>
                <img
                    alt="Benepass logo"
                    src={logo}
                    width="150"
                />
            </Grid>
        );
    }

    return (
        <div style={{backgroundColor, flexDirection: 'row', width: '100%', marginBottom: 50}}>
            {employerPoweredBy}
        </div>
    );
}

export default FooterBar;
